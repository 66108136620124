class NewsletterForm extends HTMLElement {
  constructor() {
    super()
    this.formElement = this.querySelector('form')
    this.emailField = this.querySelector('input[type="email"]')
    this.button = this.querySelector('button')
    this.successMessage = this.querySelector('#ContactFooter-success')
    this.errorMessage = this.querySelector('#ContactFooter-error')
    this.emailErrorMessage = this.querySelector('#ContactFooter-email-error')
    this.klaviyoListId = this.dataset.listId
    if (this.formElement) {
      this.formElement.addEventListener('submit', this.onFormSubmit.bind(this))
    }
  }

  parseJSON(string) {
    let json = {}
    string = string.replace(/<!--.*-->/g, '')
    try {
      json = JSON.parse(string)
    } catch (e) {
      console.log(e)
    }
    return json
  }

  async onFormSubmit(event) {
    event.preventDefault()
    if (!this.emailField) {
      return
    }

    this.query = {
      email: this.emailField.value,
      g: this.klaviyoListId
    }

    const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const checkValid = isValid.test(this.emailField.value)

    if (!checkValid) {
      if (!checkValid) {
        event.preventDefault()
        this.emailField.classList.add('input-error')
        this.errorMessage.toggleAttribute('hidden', false)
        this.showEmailError('Please enter a valid email address.')
      }
      return
    }

    const query = Object.keys(this.query).map(k => {
      let value = this.query[k]
      return `${k}=${value}`
    }).join('&')

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: 'POST',
      body: query,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache"
      },
    })
      .then((response) => response.text())
      .then((responseText) => {
        const parsed = this.parseJSON(responseText)
        if (parsed && parsed['success']) {
          this.showSuccess(true)
          this.showError('')
        } else {
          this.showError('Whoops, something went wrong')
          console.error('newsletter', parsed)
        }
      })
  }

  showSuccess(isSuccessful) {
    if (this.successMessage) {
      this.successMessage.toggleAttribute('hidden', !isSuccessful)
    }

    if (isSuccessful && this.formElement) {
      this.formElement.classList.add('hidden')
    }
  }

  showError(isError) {
    if (isError) {
      this.showSuccess(false)
    }

    if (this.errorMessage) {
      this.errorMessage.toggleAttribute('hidden', false)
      this.errorMessage.innerHTML = isError
    }
  }

  showEmailError(isError) {
    if (isError) {
      this.showSuccess(false)
    }

    if (this.emailErrorMessage) {
      this.errorMessage.toggleAttribute('hidden', true)
      this.emailErrorMessage.toggleAttribute('hidden', false)
      this.emailErrorMessage.innerHTML = isError
    }
  }
}

customElements.define('newsletter-form', NewsletterForm)