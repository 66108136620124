const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const addPreloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = `${url}&width=700`;
  document.head.appendChild(link);
}

window.addEventListener('DOMContentLoaded', () => {
  const LAZY_LOAD_SELECTOR = '.js-lazy-load'

  const lazyLoadEls = Array.prototype.slice.call(document.querySelectorAll(LAZY_LOAD_SELECTOR))

  if (lazyLoadEls.length) {
    const config = {
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0.5) {
          const target = entry.target
          const dataSrcSet = target.getAttribute('data-srcset');

          if (dataSrcSet) {
            target.setAttribute('srcset' , dataSrcSet);
            observer.unobserve(target);
          }
        }
      });
    },config);

    lazyLoadEls.forEach(lazyLoadEl => {
      if (isInViewport(lazyLoadEl)) {
        const isMobile = lazyLoadEl.getAttribute('data-mobile');
        if (isMobile) {
          const src = lazyLoadEl.getAttribute('data-preload');
          src && addPreloadImage(src);
        }
        lazyLoadEl.setAttribute('loading', 'eager');
        const dataSrcSet = lazyLoadEl.getAttribute('data-srcset');
        if (dataSrcSet) {
          lazyLoadEl.setAttribute('srcset' , dataSrcSet);
        }
      } else {
        observer.observe(lazyLoadEl);
      }
    });
  }
})
