import Carousel from "@/scripts/lib/carousel";
class SupportMenu extends HTMLElement {
  constructor () {
    super ()

    this.listMenuParents = this.querySelectorAll('.js-parent-menu')
    this.overlay = document.querySelector('[data-header-overlay]')
    this.innerOverlay = this.overlay?.querySelector('.header-overlay__inner')
    this.sliderContainer = this.querySelector('.swiper')
    this.initCarousel()

    const viewportWidth = parseFloat(this.getViewportWidth())

    if(viewportWidth >= 768) {
      this.handleToggleMenu();
      this.setLeftPosition();
    }
  
    window.addEventListener('resize', () => {
      this.setLeftPosition();
      this.handleToggleMenu();
    }, true)

    this.hideHeaderOnScroll();
  }

  initCarousel() {
    this.carousel = Carousel(this.sliderContainer, {
      slidesPerView: 'auto',
      spaceBetween: 16,
      breakpoints: {
        720: {
          spaceBetween: 28
        }
      }
    });
  }

  getViewportWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  handleToggleMenu() {
    this.listMenuParents.forEach(parent => {
      const heading = parent.querySelector('.js-heading')
      const submenu = parent.querySelector('.js-submenu')

      parent.addEventListener('mouseenter', () => {
        if(window.innerWidth >= 768) {
          this.closeAll()
          heading.classList.add('active')
          submenu.classList.add('active')
  
          this.overlay?.classList.add('is-active')
          this.innerOverlay?.classList.add('is-visible')
        }
      })
      parent.addEventListener('mouseleave', () => {
        if(window.innerWidth >= 768) {
          this.closeAll()
        }
      })
    })
  }

  closeAll() {
    this.listMenuParents.forEach(parent => {
      const heading = parent.querySelector('.js-heading')
      const submenu = parent.querySelector('.js-submenu')

      heading.classList.remove('active')
      submenu.classList.remove('active')
      this.overlay?.classList.remove('is-active')
      this.innerOverlay?.classList.remove('is-visible')
    })
  }

  setLeftPosition () {
    this.listMenuParents.forEach(parent => {
      let leftPosition = 0
      const submenu = parent.querySelector('.js-submenu')
      leftPosition = submenu ? parent.getBoundingClientRect().left : 0

      if (leftPosition > 0) {
        parent.style.setProperty('--left-position', `${leftPosition.toFixed() - 1}px`)
      }
    })
  }

  hideHeaderOnScroll () {
    let didScroll = false
    let lastScrollTop = 0
    const delta = 50
    const navbarHeight = this.offsetHeight

    window.addEventListener('scroll', () => {
      didScroll = true
    })

    setInterval(() => {
      if (!didScroll) return
      hasScrolled()
      didScroll = false
    }, 300)

    const hasScrolled = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop

      if (Math.abs(lastScrollTop - st) <= delta) return

      if (st > lastScrollTop && st > navbarHeight) {
        this.classList.add('nav-up')
      } else {
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          this.classList.remove('nav-up')
        }
      }
      lastScrollTop = st
    }
  }
}
  
window.customElements.define('support-menu', SupportMenu)
  